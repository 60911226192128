<template>
    <CRow class="mt-5 row justify-content-md-center">
        <CCol md="3">
            <div class="ml-2"><h4><strong>兌換碼登錄</strong></h4><h6>管理您的兌換碼</h6></div>

            <form @submit.prevent="addVoucher" class="mt-5 mb-5">
                <p><strong class="form-text text-muted w-100">兌換這組代碼後將存入清單，您可以在左側 <a href="/marketReport">「選址」</a>功能中的"報表授權模式"下拉選單，選擇使用此兌換碼來產生報表。</strong>
                    <small class="form-text text-muted w-100"> (若無兌換碼請改用"付費加購方案"替代,至右側<a href="/myAccount">「我的交易」</a>點擊[加購]取得報表授權)</small>
                </p>
                <p></p>


                <div role="group" class="form-group">
                    <CInput v-model="code" placeholder="輸入兌換碼"></CInput>
                </div>
                <button type="submit" class="btn btn-success btn-block "><span class="text-white">加入至清單</span></button>
                <div class="text-right">
                    <button type="button" class="btn btn-sm btn-link" @click="showRedeemCodeModal=true">&laquo;使用條款及條件&raquo;</button>
                </div>

            </form>
        </CCol>
        <CCol md="8">
            <CRow class="mt-3">
                <CCol>
                    <div class="card">
                        <CCardHeader>
                            <CIcon name="cil-list" height="20"/>
                            <span class="h6"> 啟用兌換碼清單</span></CCardHeader>
                        <div class="card-body" style="padding: 5px;">
                            <div class="sm-table-container">
                                <vueTable2 :apiUrl="voucherApiUrl" :fieldsDef="voucherFieldsDef"
                                           :detailRowComponent="voucherDetailRowComponent"
                                           :key="listUpdate"></vueTable2>
                            </div>
                        </div>
                        <br><br>
                    </div>
                </CCol>
                <CModal
                        title="Message"
                        color="danger"
                        :show.sync="showMessageModal">
                    <h5 class="text-center">
                        <CIcon name="cil-x-circle" customClasses="custom-icon-dange-w50p"></CIcon>
                        <br>{{errorMessage}}
                    </h5>
                    <template #footer>
                        <CButton @click="showMessageModal = false" color="danger">關閉</CButton>
                    </template>
                </CModal>
                <CModal
                        title="Message"
                        color="success"
                        :show.sync="showActiveVoucherMessageModal">
                    <h5 class="text-center">
                        <CIcon name="cil-check-circle" customClasses="custom-icon-sucess-w50p"></CIcon>
                        <br>{{errorMessage}}
                    </h5>
                    <template #footer>
                        <CButton @click="showActiveVoucherMessageModal = false" color="success">關閉</CButton>
                    </template>
                </CModal>
            </CRow>
        </CCol>

        <CModal
                title="兌換碼使用條款及條件" size="lg"
                color="success"
                :show.sync="showRedeemCodeModal">
            <div class="text-left terms-content" v-html="redeemCodeTermsContent"></div>
            <template #footer>
                <CButton @click="showRedeemCodeModal=false" color="light">關閉</CButton>
            </template>
        </CModal>
    </CRow>
</template>

<script>
    import Vue from "vue";
    import axios from 'axios';
    import appSettings from '../../util/settings';
    import store from '../../util/store';
    import vueTable2 from "../../components/DisplayTemplate/vueTable2.vue"
    import voucherDetailRow from "./VoucherList.vue";

    Vue.component("voucher-detail-row", voucherDetailRow);

    let pageSize = appSettings.PerPage;
    let fields = [
        {
            name: 'Code',
            title: '兌換碼'
        },
        {
            name: 'Name',
            title: '名稱'
        },
        /*
        {
            name: 'ActiveDate',
            title: '啟用日期'
        },
        {
            name: 'ValidateDate',
            title: '有效時間'
        },
        */
        {
            name: 'VoucherCount',
            title: '可用次數'
        },
        {
            name: 'IsValid',
            title: '狀態',
            formatter: value => {
                let showText = "";
                if (value) {
                    showText = "有效";
                } else {
                    showText = "無效";
                }
                return showText;
            },
        },
        /*
        {
            name: 'Message',
            title: '備註'
        },
        */
        // {
        //     name: '',
        //     title: 'Data'
        // },
    ];

    export default {
        components: {
            vueTable2
        },
        data() {
            return {
                code: '',
                showMessageModal: false,
                showActiveVoucherMessageModal: false,
                errorMessage: "",
                voucherApiUrl: appSettings.GetVoucherList + "?userToken=" + encodeURIComponent(store.state.token) + "&perPage=" + pageSize,
                voucherFieldsDef: fields,
                voucherDetailRowComponent: "voucher-detail-row",

                redeemCodeTermsContent: "",
                showRedeemCodeModal: false,

                listUpdate: 1
            }
        },
        methods: {
            addVoucherResult(response) {
                let data = response.data;
                this.errorMessage = data.Message;

                if (data.active) {
                    this.showActiveVoucherMessageModal = true;
                    this.listUpdate++;
                } else {
                    this.showMessageModal = true;
                }

                this.code = "";
            },
            addVoucher() {
                let code = this.code;
                axios.post(appSettings.AddVoucher,
                    {
                        idToken: store.state.token,
                        code
                    })
                    .then(this.addVoucherResult)
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getTerms() {
                let vm = this;
                axios.post(appSettings.GetRedeemCodeTerms, {})
                    .then(function (response) {
                        if (response.data !== undefined) {
                            //console.log(response.data)
                            vm.redeemCodeTermsContent = response.data;
                        }
                        //console.log(vm.serviceRuleContent);
                    })
            }
        },
        created() {
            this.getTerms();
        },
    }
</script>