<template> 
    <CRow>
       <CCol >
            <CCard  >
             <table class="table table-clear table-sm">
                 <tr>
                     <td class="left"><strong>啟用日期:</strong></td>  
                     <td>{{rowData.ActiveDate}}</td> 
                 </tr>
                 <tr>
                     <td class="left"><strong>有效時間:</strong></td>  
                     <td>{{rowData.ValidateDate}}</td> 
                 </tr>   
                 <tr>
                     <td class="left"><strong>備註:</strong></td>  
                     <td class="text-danger">{{rowData.Message}}</td> 
                 </tr>
             </table>
              <CCard>
                <CCardHeader><span class="text-success text-center">  <CIcon
                name="cil-av-timer"
                height="17" />使用時間</span></CCardHeader> 
                    <div style=" margin:4px, 4px; 
                            padding:4px; 
                            background-color: #fff;  
                            height: 200px; 
                            overflow-x: hidden; 
                            overflow-x: auto; 
                            text-align:justify; ">
                        <light-timeline :items='items'>
                            <template slot='content' slot-scope='{ item }'>
                                <small>{{item.content}}</small>
                                </template>
                        </light-timeline>
                    </div> 
                </CCard>  
            </CCard> 
       </CCol> 
     
    </CRow>
    
    <!--
    <div> 
        <CCard>
            <CCardBody>
                <vueTable2 :apiUrl="voucherApiUrl" :fieldsDef="voucherFieldsDef"></vueTable2>  
            </CCardBody>
        </CCard> 
    </div>
    -->
</template>        

<script>
    import Vue from "vue";
    import appSettings from '../../util/settings';
    import store from '../../util/store';
    import axios from 'axios';
    //import vueTable2 from "../../components/DisplayTemplate/vueTable2.vue" 
    import LightTimeline from "vue-light-timeline";
    Vue.use(LightTimeline);
   
    /*
    let pageSize = appSettings.PerPage;
    let fields = [
        {
            name: 'UsedDate',
            title: '使用時間'
        }
    ];
    */

   const theme = 'red';


    export default {
        components: {  
           // vueTable2, 
        },
      
        props: {
            rowData: {
                type: Object,
                required: true
            },
            rowIndex: {
                type: Number
            }
        },  
        data() {
            return {
                   // voucherApiUrl: appSettings.GetVoucherDetailList +"?userToken=" + 
                   //         encodeURIComponent(store.state.token) + "&perPage=" +pageSize + "&code=" + this.rowData.Code, 
                   // voucherFieldsDef: fields, 

              items: []
                  
            }
                
        },
        methods: {
           getTimelineData(){
                var vm= this;
                var voucherApiUrl= appSettings.GetVoucherDetailList +"?userToken=" + 
                                   encodeURIComponent(store.state.token) + "&perPage=1000000" + "&page=1&code=" + this.rowData.Code;
               
                var color=['orange','purple','green','pink','mediumspringgreen']
                axios.get(voucherApiUrl)
                    .then(function(response){
                        if(response.data.data != undefined)
                        {
                            var tagCatCount =-1;
                            var preTag="";
                            response.data.data.forEach(function(d,i){  
                                let strdate = d.UsedDate.substring(0,10);   
                                //console.log("strdate:"+strdate);
                                //console.log("preTag:" + preTag);
                                if(preTag !== strdate){ 
                                    tagCatCount+=1;  
                                    preTag = strdate;
                                } 
                                vm.items.push({
                                                 tag:strdate,
                                                 content:d.UsedDate + '產出報表',
                                                 color:color[tagCatCount%5]
                                 });   
                            });  
                        }
                }); 
           },
        },
        created(){
            this.getTimelineData();
        }
         
    }
</script>